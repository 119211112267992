import apiClient, { ApiModel, InkyApiError, InkyApiStatus } from './InkyAPI';
import { AxiosResponse, CancelTokenSource } from 'axios';
import {
    DashboardHomeListEntry,
    DashboardProduct,
    DashboardSeries,
    DashBundle,
    ItemOrder,
    PricingTier,
    RestrictedTerritory,
} from '../Models/Dashboard';
import { IpTagSummary } from '../Models/Dashboard/IpTagSummary';
import { FullImsItem, ImsItem } from '../Components/Dashboard/ImsLink';
import {
    DashCustomOrderNoteRequest,
    OrderNoteLogLevel,
} from '../Models/Dashboard/DashOrder';
import DashUser, {
    DashCustomUserNoteRequest,
    NukeUserSubscriptionRequest,
    UserNoteLogLevel,
} from '../Models/Dashboard/DashUser';
import DashUserDetails, {
    DashUserDetailsDTO,
} from '../Models/Dashboard/DashUserDetails';
import { OrderSummaryAdmin } from '../Models/Order';
import { DashShipmentProviderAccountInfo } from '../Models/Dashboard/DashShipmentProvider';
import { DiscoverItem } from '../Models/DiscoverItem';
import AverageOrderValue from '../Models/Dashboard/Reporting/AverageOrderValue';
import AnalyticsAverageOrderProductQuantity from '../Models/Dashboard/Reporting/AnalyticsAverageOrderProductQuantity';
import AnalyticsUniqueCustomers from '../Models/Dashboard/Reporting/AnalyticsUniqueCustomers';
import TopSellingProduct from '../Models/Dashboard/Reporting/TopSellingProduct';
import TopSellingProducts from '../Models/Dashboard/Reporting/TopSellingProducts';
import { DashMessageItem } from '../Models/Dashboard/DashMessageItem';
import {
    InkyPortProductInfo,
    InkyPortProductInfoDTO,
} from '../Models/Dashboard/InkyPortProductInfo';
import DashboardReport from '../Models/Dashboard/Reporting/DashboardReport';
import { DashUserDetailsWithPurchaseDataDTO } from '../Models/Dashboard/DashUserDetailsWithPurchaseData';
import {
    DashboardSalesCampaign,
    DashboardSalesCampaignDTO,
    DashboardSalesCampaignShortDTO,
    DashboardSalesProductDTO,
} from '../Models/Dashboard/Sales/DashboardSalesCampaign';
import {
    PaginationRequestParams,
    PaginationResponseParams,
} from '../Devon/Pagination/PaginationTypes';
import { DashboardInkyEmailTemplate } from '../Models/Dashboard/EmailTemplates/DashboardInkyEmailTemplate';
import {
    DashboardAuthEmailTemplate,
    DashboardAuthEmailTemplateListing,
} from '../Models/Dashboard/EmailTemplates/AuthEmailTemplate';
import { DashCarousel, DashCarouselDTO } from '../Models/Dashboard/DashCarousel';
import { DashHtmlComponent, DashHtmlComponentDTO } from '../Models/Dashboard/DashHtmlComponent';
import { CreateDashPublisher, DashPublisher } from '../Models/Dashboard/Publishers/DashPublisher';
import {
    EmailProviderSendQuota,
    EmailProviderSendStatistics, EmailProviderSendStatisticsDTO,
    EmailProviderVerifiedIdentity, ReputationMetricDataPoint, ReputationMetricDataPointDTO,
} from '../Models/Dashboard/EmailTemplates/EmailProviders';
import {
    UserExportJob,
    UserExportJobDTO,
    UserExportListing,
    UserExportListingDTO,
} from '../Models/Dashboard/UserExport';
import { DashTransactionHistoryItem } from '../Models/Dashboard/DashTransactionHistoryItem';
// import { SalePost, SalesResponseData } from '../Components/Sales/SalesModel';

class DashboardAPI {
    /**
     * Get All products that match parameter criteria
     * @param cancelTokenSource
     * @param query
     * @param start
     * @param count
     * @param includeBundles
     * @param publishStatus
     * @param includeRemoved Only relevant if the publish status is null (all products).
     */
    getAllProducts(
        cancelTokenSource: CancelTokenSource,
        query?: string,
        start?: number,
        count?: number,
        includeBundles?: boolean,
        publishStatus?: string,
        includeRemoved?: boolean,
        showOnlyOnSale?: boolean,
        includeChapters?: boolean,
        distributionType?: DistributionType
    ): Promise<ApiModel<DashboardProduct[]>> {
        const params = [];

        if (query) params.push(['query', query]);
        if (start) params.push(['start', start]);
        if (count) params.push(['count', count]);
        if (includeBundles) params.push(['includeBundles', includeBundles]);
        if (publishStatus) params.push(['publishStatus', publishStatus]);
        if (includeRemoved) params.push(['includeRemoved', includeRemoved]);
        if (showOnlyOnSale) params.push(['showOnlyOnSale', showOnlyOnSale]);
        if (includeChapters) params.push(['includeChapters', showOnlyOnSale]);
        if (distributionType) {
            switch (distributionType) {
                case 'Digital':
                    params.push(['distributionType', 0]);
                    break;
                case 'Print':
                    params.push(['distributionType', 1]);
                    break;
                default:
                    break;
            }
        }

        return apiClient.getResponse<DashboardProduct[]>(
            '/dashboard/product',
            cancelTokenSource,
            params
        );
    }
    getAllProductsV2<T = DashboardProduct>(
        abortSignal: AbortSignal,
        query?: string,
        start?: number,
        count?: number,
        includeBundles?: boolean,
        publishStatus?: string,
        includeRemoved?: boolean,
        showOnlyOnSale?: boolean,
        includeChapters?: boolean,
        distributionType?: DistributionType
    ): Promise<ApiModel<T[]>> {
        const params = [];

        if (query) params.push(['query', query]);
        if (start) params.push(['start', start]);
        if (count) params.push(['count', count]);
        if (includeBundles) params.push(['includeBundles', includeBundles]);
        if (publishStatus) params.push(['publishStatus', publishStatus]);
        if (includeRemoved) params.push(['includeRemoved', includeRemoved]);
        if (showOnlyOnSale) params.push(['showOnlyOnSale', showOnlyOnSale]);
        if (includeChapters) params.push(['includeChapters', showOnlyOnSale]);
        if (distributionType) {
            switch (distributionType) {
                case 'Digital':
                    params.push(['distributionType', 0]);
                    break;
                case 'Print':
                    params.push(['distributionType', 1]);
                    break;
                default:
                    break;
            }
        }

        return apiClient.getResponseV2<T[]>(
            '/dashboard/product',
            abortSignal,
            params
        );
    }

    getProductsForSeries(
        abortSignal: AbortSignal,
        seriesId: number
    ): Promise<DashboardProduct[]> {
        return apiClient.getV4(
            `/dashboard/product/for/series/${seriesId}`,
            abortSignal
        );
    }

    getProductById<T = DashboardProduct>(
        abortSignal: AbortSignal | null,
        id: number
    ): Promise<T> {
        return apiClient.getV4(`/dashboard/product/${id}`, abortSignal);
    }

    addCustomOrderNote(
        cancelTokenSource: CancelTokenSource,
        orderId: number,
        message: string
    ) {
        // Create paypload
        const payload = new DashCustomOrderNoteRequest();
        payload.orderId = orderId;
        payload.logLevel = OrderNoteLogLevel.Info;
        payload.message = message;

        return apiClient.post<Object>(
            '/dashboard/orders/addCustomNote',
            payload,
            cancelTokenSource
        );
    }

    addCustomUserNote(
        cancelTokenSource: CancelTokenSource,
        userId: number,
        message: string
    ) {
        // Create paypload
        const payload = new DashCustomUserNoteRequest();
        payload.userId = userId;
        payload.logLevel = UserNoteLogLevel.Info;
        payload.message = message;

        return apiClient.post<Object>(
            '/dashboard/users/addCustomNote',
            payload,
            cancelTokenSource
        );
    }

    nukeUserSubscription(cancelTokenSource: CancelTokenSource, userId: number) {
        // Create paypload
        const payload = new NukeUserSubscriptionRequest();
        payload.userId = userId;

        return apiClient.post<Object>(
            '/user/deletesubscriptionandcustomer',
            payload,
            cancelTokenSource
        );
    }

    getSeries(
        cancelTokenSource: CancelTokenSource,
        query?: string,
        count?: number,
        publishStatus?: string
    ): Promise<AxiosResponse<DashboardSeries[]>> {
        if (query || count || publishStatus) {
            let params = [];
            if (query) {
                params = [...params, ['query', query]];
            }
            if (count) {
                params = [...params, ['count', '' + count]];
            }
            if (publishStatus) {
                params = [...params, ['publishStatus', publishStatus]];
            }

            return apiClient.get<DashboardSeries[]>(
                '/dashboard/series',
                cancelTokenSource,
                params
            );
        } else {
            return apiClient.get<DashboardSeries[]>(
                '/dashboard/series',
                cancelTokenSource
            );
        }
    }

    getSeriesById<T = DashboardSeries>(
        abortSignal: AbortSignal | null,
        id: number
    ): Promise<T> {
        return apiClient.getV4<T>(
            `/dashboard/series/${id}`,
            abortSignal
        );
    }

    getBundles(
        cancelTokenSource: CancelTokenSource,
        query?: string,
        count?: number
    ): Promise<AxiosResponse<DashBundle[]>> {
        if (query) {
            let params = [['query', query]];
            if (count) {
                params = [...params, ['count', '' + count]];
            }
            return apiClient.get<DashBundle[]>(
                '/dashboard/bundles',
                cancelTokenSource,
                params
            );
        } else {
            return apiClient.get<DashBundle[]>(
                '/dashboard/bundles',
                cancelTokenSource
            );
        }
    }

    async getAllSalesCampaigns(
        fromIndex?: number,
        count?: number,
        search?: string,
        abortSignal?: AbortSignal
    ) {
        let path = '/dashboard/sales/';
        const temp = [];
        if (fromIndex) {
            temp.push('fromIndex=' + fromIndex);
        }
        if (count) {
            temp.push('count=' + count);
        }
        if (search) {
            temp.push('search=' + search);
        }
        if (temp.length > 0) {
            path = path + '?' + temp.join('&');
        }

        const result = await apiClient.getV4<
            ApiModel<{
                items: DashboardSalesCampaignShortDTO[];
                fullCount: number;
            }>
        >(path, abortSignal);

        if (result.status.type === 'Success') {
            return result.response;
        }
    }

    async getSalesCampaign(id: number, abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<
            ApiModel<DashboardSalesCampaignDTO>
        >('/dashboard/sales/getCampaignById/' + id, abortSignal);

        if (result.status.type === 'Success') {
            return result.response;
        }
    }
    async downloadSalesCampaign(id: number) {
        return apiClient.getFile(
            '/dashboard/sales/exportAsExcel/' + id,
            {},
            null
        );
    }

    async getSalesCampaignProductsForSeries(
        id: number,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.getV4<
            ApiModel<DashboardSalesProductDTO[]>
        >('/dashboard/sales/getSeriesInfo/' + id, abortSignal);

        if (result.status.type === 'Success') {
            return result.response;
        }
    }

    async alterSalesCampaign(
        salesCampaign: DashboardSalesCampaign,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            DashboardSalesCampaignDTO,
            ApiModel<DashboardSalesCampaignDTO>
        >('/dashboard/sales/alter', salesCampaign.toApi(), abortSignal);

        if (result.status.type === 'Success') {
            return DashboardSalesCampaign.createFromApi(result.response);
        } else {
            console.error('Something went wrong saving');
        }
    }
    async createSalesCampaign(
        salesCampaign: DashboardSalesCampaign,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            DashboardSalesCampaignDTO,
            ApiModel<DashboardSalesCampaignDTO>
        >('/dashboard/sales/create', salesCampaign.toApi(), abortSignal);

        if (result.status.type === 'Success') {
            return DashboardSalesCampaign.createFromApi(result.response);
        } else {
            console.error('Something went wrong saving');
        }
    }

    async publishSalesCampaign(
        salesCampaignId: number,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            null,
            ApiModel<DashboardSalesCampaignDTO>
        >('/dashboard/sales/publish/' + salesCampaignId, null, abortSignal);

        if (result.status.type === 'Success') {
            return DashboardSalesCampaign.createFromApi(result.response);
        } else {
            console.error('Something went wrong publishing');
        }
    }

    async unpublishSalesCampaign(
        salesCampaignId: number,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            null,
            ApiModel<DashboardSalesCampaignDTO>
        >('/dashboard/sales/unpublish/' + salesCampaignId, null, abortSignal);

        if (result.status.type === 'Success') {
            return DashboardSalesCampaign.createFromApi(result.response);
        } else {
            console.error('Something went wrong publishing');
        }
    }

    // createSalesCampaigns(data: SalePost): Promise<AxiosResponse<SalePost>> {
    //     return apiClient.post<SalePost>("/dashboard/sales/create", data);
    // }

    getAllWheelItems(
        cancelTokenSource: CancelTokenSource
    ): Promise<AxiosResponse<DashboardHomeListEntry[]>> {
        return apiClient.get<DashboardHomeListEntry[]>(
            '/dashboard/home/entry',
            cancelTokenSource
        );
    }

    alterWheelItems(
        cancelTokenSource: CancelTokenSource,
        homeListEntries: DashboardHomeListEntry[]
    ) {
        return apiClient.post<DashboardHomeListEntry[]>(
            '/dashboard/home/entry/alter',
            homeListEntries,
            cancelTokenSource
        );
    }

    removeWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
        return apiClient.post<Object>(
            '/dashboard/home/entry/remove?id=' + id,
            null,
            cancelTokenSource
        );
    }

    publishWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
        return apiClient.post<Object>(
            '/dashboard/home/entry/publish?id=' + id,
            null,
            cancelTokenSource
        );
    }

    unPublishWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
        return apiClient.post<Object>(
            '/dashboard/home/entry/unpublish?id=' + id,
            null,
            cancelTokenSource
        );
    }

    reOrderWheelItems(
        cancelTokenSource: CancelTokenSource,
        wheelOrders: ItemOrder[]
    ) {
        return apiClient.post<ItemOrder[]>(
            '/dashboard/home/entry/reOrder',
            wheelOrders,
            cancelTokenSource
        );
    }
    invalidateCache(cancelTokenSource: CancelTokenSource) {
        return apiClient.post(
            '/dashboard/cache/invalidateAll',
            {},
            cancelTokenSource
        );
    }

    addNewWheelItem(
        cancelTokenSource: CancelTokenSource,
        homeListEntry: DashboardHomeListEntry
    ) {
        console.log('Adding new wheel item');
        console.log(homeListEntry);
        return apiClient.post<DashboardHomeListEntry>(
            '/dashboard/home/entry/new',
            homeListEntry,
            cancelTokenSource
        );
    }

    addHomePageConfiguration(cancelTokenSource: CancelTokenSource, id: number) {
        console.log('test ' + id);
        return apiClient.post(
            '/dashboard/homepage/top?typeId=' + id,
            cancelTokenSource
        );
    }

    addHomePageMessage(
        cancelTokenSource: CancelTokenSource,
        message: DashMessageItem
    ) {
        return apiClient.post(
            '/dashboard/homepage/messaging/set',
            message,
            cancelTokenSource
        );
    }

    getAllRestrictedTerritories(
        cancelTokenSource: CancelTokenSource
    ): Promise<AxiosResponse<RestrictedTerritory[]>> {
        return apiClient.get<RestrictedTerritory[]>(
            '/dashboard/home/restrictedTerritories',
            cancelTokenSource
        );
    }

    getAllPriceTiers(
        cancelTokenSource: CancelTokenSource
    ): Promise<AxiosResponse<PricingTier[]>> {
        return apiClient.get<PricingTier[]>(
            '/dashboard/home/pricing/tiers',
            cancelTokenSource
        );
    }

    getIpTagList(
        cancelTokenSource: CancelTokenSource,
        query?: string
    ): Promise<AxiosResponse<IpTagSummary[]>> {
        const params = [];
        if (query) {
            params.push(['query', query]);
        }
        return apiClient.get<IpTagSummary[]>(
            '/dashboard/ipTags',
            cancelTokenSource,
            params
        );
    }

    getImsItems(
        cancelTokenSource: CancelTokenSource,
        query?: string
    ): Promise<AxiosResponse<ImsItem[]>> {
        const params = [];
        if (query) {
            params.push(['query', query]);
        }
        return apiClient.get<ImsItem[]>(
            '/dashboard/ims',
            cancelTokenSource,
            params
        );
    }

    async getInkyPortProducts(
        cancelTokenSource: CancelTokenSource,
        query?: string
    ): Promise<InkyPortProductInfo[]> {
        const params = [];
        if (query) {
            params.push(['query', query]);
        }
        const resp = await apiClient.getResponse<InkyPortProductInfoDTO[]>(
            '/dashboard/ims',
            cancelTokenSource,
            params
        );

        if (resp.status.type === 'Success') {
            return resp.response.map((x) =>
                InkyPortProductInfo.createFromApi(x)
            );
        }
    }

    async getInkyPortProduct(
        cancelTokenSource: CancelTokenSource,
        sku: string
    ): Promise<InkyPortProductInfo> {
        const resp = await apiClient.getResponse<InkyPortProductInfoDTO>(
            `/dashboard/ims/${sku}`,
            cancelTokenSource
        );

        if (resp.status.type === 'Success') {
            return InkyPortProductInfo.createFromApi(resp.response);
        }
    }

    getImsItemDetails(
        cancelTokenSource: CancelTokenSource,
        inventoryId: string
    ): Promise<AxiosResponse<FullImsItem>> {
        return apiClient.get<FullImsItem>(
            '/dashboard/ims/' + inventoryId,
            cancelTokenSource
        );
    }

    getOrderSummaries(
        cancelTokenSource: CancelTokenSource,
        orderState: string = null,
        filterFromDate: Date = null,
        filterOrderSelectTagId: number = null,
        start: number = null,
        count: number = null,
        searchText: string = null
    ): Promise<ApiModel<OrderSummaryAdmin[]>> {
        const params = [];
        if (orderState !== null) {
            params.push(['filterStatus', orderState]);
        }
        if (filterFromDate !== null) {
            params.push(['filterFromDate', filterFromDate.toISOString()]);
        }
        if (filterOrderSelectTagId !== null) {
            params.push(['filterTagIds', filterOrderSelectTagId]);
        }
        if (start !== null) {
            params.push(['start', start]);
        }
        if (count !== null) {
            params.push(['count', count]);
        }
        if (searchText !== null) {
            params.push(['searchText', searchText]);
        }
        //console.log(`params for orderState: ${orderState}, filterFromDate: ${filterFromDate}`);
        //console.log(params);
        return apiClient.getResponse<OrderSummaryAdmin[]>(
            '/dashboard/orders',
            cancelTokenSource,
            params
        );
    }

    async getPaginatedUsers(
        query: PaginationRequestParams,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<DashUser>>
        >('/dashboard/users/post-pagination', query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    getUsersV2(
        abortSignal: AbortSignal,
        query: string
    ): Promise<ApiModel<DashUser[]>> {
        return apiClient.getResponseV2<DashUser[]>(
            '/dashboard/users',
            abortSignal,
            [['query', query]]
        );
    }

    getUserDetails(
        cancelTokenSource: CancelTokenSource,
        userId: number
    ): Promise<ApiModel<DashUserDetailsDTO>> {
        return apiClient.getResponse<DashUserDetailsDTO>(
            'dashboard/users/products',
            cancelTokenSource,
            [['userId', '' + userId]]
        );
    }

    getUserDetailsWithPurchaseData(
        cancelTokenSource: CancelTokenSource,
        userId: number
    ): Promise<ApiModel<DashUserDetailsWithPurchaseDataDTO>> {
        return apiClient.getResponse<DashUserDetailsWithPurchaseDataDTO>(
            'dashboard/users/' + userId,
            cancelTokenSource,
            []
        );
    }
    /*
    Only updates user.Roles for now
     */
    setUserDetails(
        cancelTokenSource: CancelTokenSource,
        dashBoardUser: DashUserDetailsDTO
    ): Promise<AxiosResponse<ApiModel<DashUserDetailsDTO>>> {
        return apiClient.postV2<ApiModel<DashUserDetailsDTO>>(
            '/dashboard/users/alter/' + dashBoardUser.userId,
            dashBoardUser,
            cancelTokenSource
        );
    }

    /**
     * Filters out new Product Ids and adds them as comped to the user
     * @param cancelTokenSource
     * @param dashUserDetails
     */
    addUserCompensatedProducts(
        cancelTokenSource: CancelTokenSource,
        dashUserDetails: DashUserDetailsDTO
    ): Promise<AxiosResponse<any>> {
        return apiClient.postV2<ApiModel<any>>(
            '/dashboard/users/products/compAdd',
            dashUserDetails,
            cancelTokenSource
        );
    }

    /**
     * removes a userPurchaseData entry by id, does not affect any other tables
     * @param cancelTokenSource
     * @param userPurchaseDataId
     */
    removeUserProduct(
        cancelTokenSource: CancelTokenSource,
        userPurchaseDataId: number
    ) {
        return apiClient.post<ApiModel<any>>(
            '/dashboard/users/products/delete/' + userPurchaseDataId,
            null,
            cancelTokenSource
        );
    }

    /**
     * @deprecated SKU's are not used
     */
    addUserProducts(
        cancelTokenSource: CancelTokenSource,
        userId: number,
        skus: string[]
    ): Promise<AxiosResponse<any>> {
        const payload = {
            userId: userId,
            productSkus: skus,
        };

        return apiClient.post<any>(
            '/dashboard/users/products/add',
            payload,
            cancelTokenSource
        );
    }

    async deleteUser(
        userId: number,
        abortSignal?: AbortSignal
    ): Promise<boolean> {
        const res = await apiClient.postV4<null, ApiModel<null>>(
            '/dashboard/users/delete/' + userId,
            null,
            abortSignal
        );
        if (res.status.type !== 'Success') {
            throw res.status.message;
        }
        return true;
    }

    async getUserTransactions(userId: number, abortSignal?: AbortSignal): Promise<DashTransactionHistoryItem[]> {
        const res = await apiClient.getV4<ApiModel<DashTransactionHistoryItem[]>>(
            `/dashboard/users/transactions/${userId}`, abortSignal);
        if (res.status.type !== 'Success') {
            throw res.status.message;
        }
        return res.response;
    }

    ingestProduct(
        cancelTokenSource: CancelTokenSource,
        productId: number,
        remotePath: string
    ) {
        return apiClient.get(
            `/dashboard/ingest/encode/${productId}`,
            cancelTokenSource,
            [
                ['forceReEncode', 'true'],
                ['masterRecord', remotePath],
            ]
        );
    }

    getAnalyticsOrderData(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ) {
        console.log(startDate);
        console.log(endDate);

        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);

        return apiClient.get(
            '/analytics/getOrderData',
            cancelTokenSource,
            params
        );
    }
    getAnalyticsOrderDataLegacy(cancelTokenSource: CancelTokenSource) {
        return apiClient.get('/analytics/getOrderData', cancelTokenSource);
    }
    getAnalyticsUserData(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ) {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);

        return apiClient.get(
            '/analytics/getUserData',
            cancelTokenSource,
            params
        );
    }
    getAnalyticsCartData(cancelTokenSource: CancelTokenSource) {
        return apiClient.get('/analytics/getCartData', cancelTokenSource);
    }
    getAnalyticsPageMetrics(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ) {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);

        return apiClient.get(
            '/analytics/getPageMetrics',
            cancelTokenSource,
            params
        );
    }

    getSearchItemsToRemove(
        cancelTokenSource: CancelTokenSource
    ): Promise<ApiModel<DiscoverItem[]>> {
        return apiClient.getResponse('/search/getOrphans', cancelTokenSource);
    }

    getSearchItemsToAdd(
        cancelTokenSource: CancelTokenSource
    ): Promise<ApiModel<DiscoverItem[]>> {
        return apiClient.getResponse(
            '/search/getActiveNotUploaded',
            cancelTokenSource
        );
    }

    removeInactiveSearchItems(
        cancelTokenSource: CancelTokenSource
    ): Promise<AxiosResponse<DiscoverItem[]>> {
        return apiClient.post<DiscoverItem[]>(
            '/search/removeInactiveFromSearch',
            null,
            cancelTokenSource
        );
    }

    addMissingActiveSearchItems(
        cancelTokenSource: CancelTokenSource
    ): Promise<AxiosResponse<void>> {
        return apiClient.post<void>(
            '/search/uploadAllToSearch',
            null,
            cancelTokenSource
        );
    }

    async getShipmentProviderAccountInfo(
        cancelTokenSource: CancelTokenSource
    ): Promise<ApiModel<DashShipmentProviderAccountInfo>> {
        let response = null;
        response = await apiClient.getResponse(
            '/dashboard/orders/shipping/getAccountInfo',
            cancelTokenSource
        );
        return response;
    }

    async GetSalesReport(reportRequest: DashboardReport) {
        const response = await apiClient.getFile(
            '/reports/asExcelFile',
            reportRequest,
            null
        );

        return response;
    }

    async GenerateSalesReportAndSendToEmail(reportRequest: DashboardReport,emails:string[] = null) {
        let url = '/reports/asExcelFileSendEmail';
        if(emails?.length > 0) {
            url += '?' + emails.map(e => 'emails=' + e).join('&');
        }

        const response = await apiClient.postV4<DashboardReport,ApiModel<string[]>>(
            url,
            reportRequest,
            null
        );

        return response;
    }

    /* REPORTING */
    async getAverageOrderValue(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ): Promise<ApiModel<AverageOrderValue>> {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);
        return apiClient.getResponse(
            '/analytics/averageOrderValue',
            cancelTokenSource,
            params
        );
    }
    async getAverageOrderProductQuantity(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ): Promise<ApiModel<AnalyticsAverageOrderProductQuantity>> {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);
        return apiClient.getResponse(
            '/analytics/averageOrderProductQuantity',
            cancelTokenSource,
            params
        );
    }
    async getUniqueLoggedInCustomers(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date
    ): Promise<ApiModel<AnalyticsUniqueCustomers>> {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);
        return apiClient.getResponse(
            'analytics/uniqueLoggedInCustomers',
            cancelTokenSource,
            params
        );
    }
    async getTopSellingProducts(
        cancelTokenSource: CancelTokenSource,
        startDate: Date,
        endDate: Date,
        maxItems?: string
    ): Promise<ApiModel<TopSellingProducts>> {
        const params = [];
        if (startDate) params.push(['start', startDate.toISOString()]);
        if (endDate) params.push(['end', endDate.toISOString()]);
        if (maxItems) params.push('maxItems', maxItems);
        return apiClient.getResponse(
            'analytics/topSellingProducts',
            cancelTokenSource,
            params
        );
    }

    async setRoles(
        cancelTokenSource: CancelTokenSource,
        userId: string,
        role: string[]
    ): Promise<AxiosResponse<any>> {
        const payload = {
            Auth0Id: userId,
            NewRoles: role,
        };

        return apiClient.post<any>(
            '/dashboard/users/SetRoles',
            payload,
            cancelTokenSource
        );
    }

    async ValidateDashboardSalesImport(
        form: FormData,
        abortSignal?: AbortSignal
    ) {
        const response = await apiClient.postV4<
            FormData,
            ApiModel<{
                item1: DashboardSalesCampaignDTO;
                item2: string[];
            }>
        >('/dashboard/sales/ValidateFromCsv', form, abortSignal);

        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(
                response.status.message,
                InkyApiStatus.Default
            );
        }
    }
    async ValidateAndImportDashboardSalesImport(
        form: FormData,
        abortSignal?: AbortSignal
    ) {
        const response = await apiClient.postV4<
            FormData,
            ApiModel<{
                item1: DashboardSalesCampaignDTO;
                item2: string[];
            }>
        >('/dashboard/sales/CreateFromCsv', form, abortSignal);

        if (response.status.type === 'Success') {
            return response.response;
        } else {
            throw new InkyApiError(
                'Something Failed with ValidateTrilogyExport endpoint',
                InkyApiStatus.Default
            );
        }
    }

    async getPaginatedProducts(
        query: PaginationRequestParams,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<DashboardProduct>>
        >('/dashboard/product', query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

     async getPaginatedSeries(
        query: PaginationRequestParams,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<DashboardSeriesShort>>
        >('/dashboard/series', query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getPaginatedInkyEmailTemplates(
        query: PaginationRequestParams,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<DashboardInkyEmailTemplate>>
        >('/dashboard/emailTemplates/db-templates', query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }
    async getPaginatedPublishers(
        query: PaginationRequestParams,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<DashPublisher>>
        >('/dashboard/publisher/post-pagination', query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async createPublisher(publisher: CreateDashPublisher, abortSignal?: AbortSignal) {
        const result = await apiClient.postV4<CreateDashPublisher, ApiModel<string>>(
            '/dashboard/publisher/new', publisher, abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response;
    }

    async alterPublisher(publisher: DashPublisher, abortSignal?: AbortSignal) {
        const result = await apiClient.postV4<DashPublisher, ApiModel<string>>(
            '/dashboard/publisher/alter', publisher, abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response;
    }

    async removePublisher(id: number, abortSignal?: AbortSignal): Promise<void> {
        const result = await apiClient.postV4<unknown, ApiModel<string>>(
            `/dashboard/publisher/remove?id=${id}`, abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
    }

    async BatchEditPublisherVisibility(publisherIds: number[], hidden: boolean, abortSignal?: AbortSignal) {
        console.debug('BatchEditPublisherVisibility', publisherIds, hidden);

        const result = await apiClient.postV4<
            number[], ApiModel<string>
        >('/dashboard/publisher/batch-visibility?hidden='+ (hidden?"true":"false"), publisherIds, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default,
            );
        }
    }

    async getInkyTemplateById(id:string, abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<
            ApiModel<DashboardInkyEmailTemplate>
        >('/dashboard/emailTemplates/db-templates/'+id, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async alterInkyTemplateById(dashboardInkyEmailTemplate:DashboardInkyEmailTemplate, abortSignal?: AbortSignal) {
        const result = await apiClient.postV4<
            DashboardInkyEmailTemplate,ApiModel<DashboardInkyEmailTemplate>
        >(`/dashboard/emailTemplates/db-templates/${dashboardInkyEmailTemplate.id}/alter`,dashboardInkyEmailTemplate, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }
    async testInkyTemplateById(id:number,toAddresses:string[],metaDate:never, abortSignal?: AbortSignal) {
        const body:{
            ToAddresses:string[],
            MetaData:never
        } = {
            ToAddresses : toAddresses,
            MetaData : metaDate
        };

        const result = await apiClient.postV4<
            {
                ToAddresses:string[],
                MetaData:never
            },ApiModel<string>
        >(`/dashboard/emailTemplates/db-templates/${id}/test-email`,body, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getAuthTemplatesNames(abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<
            ApiModel<DashboardAuthEmailTemplateListing[]>
        >('/dashboard/emailTemplates/auth-templates', abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getAuthTemplateByName(name:string, abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<
            ApiModel<DashboardAuthEmailTemplate>
        >('/dashboard/emailTemplates/auth-templates/'+name, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getPaginatedUserOrder(
        query: PaginationRequestParams,
        userId:number,
        abortSignal?: AbortSignal
    ) {
        const result = await apiClient.postV4<
            PaginationRequestParams,
            ApiModel<PaginationResponseParams<OrderSummaryAdmin>>
        >('/dashboard/users/paginated-orders?userId='+userId, query, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getCarousels(abortSignal?: AbortSignal): Promise<DashCarousel[]> {
        const result = await apiClient.getV4<ApiModel<DashCarouselDTO[]>>(
            '/dashboard/carousels', abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response.map(DashCarousel.fromApi);
    }

    async createCarousel(carousel: DashCarousel, abortSignal?: AbortSignal): Promise<DashCarousel> {
        const result = await apiClient.postV4<DashCarouselDTO, ApiModel<DashCarouselDTO>>(
            '/dashboard/carousels/new', carousel.toApi(), abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return DashCarousel.fromApi(result.response);
    }

    async alterCarousels(carousels: DashCarousel[], abortSignal?: AbortSignal): Promise<DashCarousel[]> {
        const result = await apiClient.postV4<DashCarouselDTO[], ApiModel<DashCarouselDTO[]>>(
            '/dashboard/carousels/alter', carousels.map(c => c.toApi()), abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response.map(DashCarousel.fromApi);
    }

    async deleteCarousel(id: number, abortSignal?: AbortSignal): Promise<void> {
        await apiClient.postV4<unknown, ApiModel<unknown>>(
            `/dashboard/carousels/remove?id=${id}`, abortSignal);
    }

    async cancelUserSubscription(userId:number, pending:boolean,abortSignal?: AbortSignal) {
        const url = `/dashboard/users/unsubscribe?pending=${pending}&userId=${userId}`;
        const result = await apiClient.postV4<
            null,
            ApiModel<string>
        >(url, null, abortSignal);
        if (result.status.type === 'Success') {
            return result.response;
        } else {
            throw new InkyApiError(
                result.status.message,
                InkyApiStatus.Default
            );
        }
    }

    async getHtmlComponents(abortSignal?: AbortSignal): Promise<DashHtmlComponent[]> {
        const result = await apiClient.getV4<ApiModel<DashHtmlComponentDTO[]>>(
            '/dashboard/html-components', abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response.map(DashHtmlComponent.fromApi);
    }

    async createHtmlComponent(component: DashHtmlComponent, abortSignal?: AbortSignal): Promise<DashHtmlComponent> {
        const result = await apiClient.postV4<DashHtmlComponentDTO, ApiModel<DashHtmlComponentDTO>>(
            '/dashboard/html-components/new', component.toApi(), abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return DashHtmlComponent.fromApi(result.response);
    }

    async alterHtmlComponents(components: DashHtmlComponent[], abortSignal?: AbortSignal): Promise<DashHtmlComponent[]> {
        const result = await apiClient.postV4<DashHtmlComponentDTO[], ApiModel<DashHtmlComponentDTO[]>>(
            '/dashboard/html-components/alter', components.map(c => c.toApi()), abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response.map(DashHtmlComponent.fromApi);
    }

    async getEmailProviderSendQuota(abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<ApiModel<EmailProviderSendQuota>>(
            '/dashboard/email-provider/send-quota', abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response;
    }

    async getEmailProviderSendStatistics(abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<ApiModel<EmailProviderSendStatisticsDTO[]>>(
            '/dashboard/email-provider/send-statistics', abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response.map(EmailProviderSendStatistics.createFromApi);
    }

    async getEmailProviderReputationMetrics(abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<ApiModel<EmailProviderReputationMetricsResponse>>(
            '/dashboard/email-provider/reputation-metrics', abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }

        return {
            complaintRateMetrics: result.response.complaintRateMetrics.map(ReputationMetricDataPoint.createFromApi),
            bounceRateMetrics: result.response.bounceRateMetrics.map(ReputationMetricDataPoint.createFromApi),
        };
    }

    async getEmailProviderVerifiedIdentities(identityType?: 'EmailAddress' | 'Domain', nextToken?: string, abortSignal?: AbortSignal) {
        const body: VerifiedIdentitiesRequest = {
            identityType: identityType,
            nextToken: nextToken,
        };

        const result = await apiClient.postV4<VerifiedIdentitiesRequest, ApiModel<VerifiedIdentitiesResponse>>(
            '/dashboard/email-provider/verified-identities', body, abortSignal);
        if (result.status.type !== 'Success') {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
        return result.response;
    }


    async startUserExport(customExport?:unknown) {
        let exportRequest:unknown = {
            format: 'csv',
            fields: [
                {
                    'name': 'user_id',
                },
                {
                    'name': 'email',
                },
                {
                    'name': 'user_metadata.country',
                },
                {
                    'name': 'created_at',
                },
                {
                    'name': 'updated_at',
                },
                {
                    'name': 'last_login',
                },
                {
                    'name': 'logins_count',
                },
                {
                    'name': 'email_verified',
                },
            ],
        };

        if (customExport !== null && customExport !== undefined) {
            exportRequest = customExport;
        }

        const result = await apiClient.postV4<unknown, ApiModel<UserExportJobDTO>>(
            '/dashboard/users/user-export/start', exportRequest);
        if (result.status.type === 'Success') {
            return UserExportJob.createFromApi(result.response);
        }

        throw new InkyApiError(result.status.message, InkyApiStatus.Default);
    }
    async getUserExportList(abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<ApiModel<UserExportListingDTO[]>>(
            '/dashboard/users/user-export', abortSignal);
        if (result.status.type === 'Success') {
            return result.response.map(UserExportListing.createFromApi);
        }

        throw new InkyApiError(result.status.message, InkyApiStatus.Default);
    }

    async getUserExportJob(jobId: string, abortSignal?: AbortSignal) {
        const result = await apiClient.getV4<ApiModel<UserExportJobDTO>>(
            '/dashboard/users/user-export/' + jobId, abortSignal);
        if (result.status.type === 'Success') {
            return UserExportJob.createFromApi(result.response);
        }

        throw new InkyApiError(result.status.message, InkyApiStatus.Default);
    }
}

type EmailProviderReputationMetricsResponse = {
    complaintRateMetrics: ReputationMetricDataPointDTO[]
    bounceRateMetrics: ReputationMetricDataPointDTO[]
}

type VerifiedIdentitiesRequest = {
    identityType?: "EmailAddress" | "Domain",
    nextToken?: string
}

type VerifiedIdentitiesResponse = {
    identities: EmailProviderVerifiedIdentity[],
    nextToken?: string
}


type DistributionType = 'Digital' | 'Print';
const dashboard = new DashboardAPI();

export default dashboard;
