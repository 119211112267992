import { Address } from './Address';
import { NameTuple } from './FilterTuple';
import { Selectable } from './Selectable';
import Dinero, { Currency } from 'dinero.js';
import { CountedOrderItem, OrderItem, OrderItemDTO } from './OrderItem';
import { OrderShippingMethod } from './OrderShippingMethod';
import { OrderPrices } from './OrderPrices';
import { ShippingRate, ShippingRateDTO } from './ShippingRate';
import { PriceDetails, PriceDetailsDTO, Purchase } from './Purchase';
import dayjs, { Dayjs } from 'dayjs';

export enum OrderStatus {
    PendingPayment = "PendingPayment",
    ConfirmedByClient = "ConfirmedByClient",
    Scheduled = "Scheduled",
    PendingProcessing = "PendingProcessing",
    Processing = "Processing",
    OnHold = "OnHold",
    Cancelled = "Cancelled",
    Completed = "Completed"
}

export const OrderStatusReadable = [
    { value: OrderStatus.PendingPayment, label: 'Pending Payment' },
    { value: OrderStatus.ConfirmedByClient, label: 'Payment TBC' },
    { value: OrderStatus.Scheduled, label: 'Scheduled' },
    { value: OrderStatus.PendingProcessing, label: 'Pending Processing' },
    { value: OrderStatus.Processing, label: 'Processing' },
    { value: OrderStatus.OnHold, label: 'Blocked' },
    { value: OrderStatus.Completed, label: 'Completed' },
    { value: OrderStatus.Cancelled, label: 'Cancelled' },
];

export enum DistributionType {
    Digital = 0,
    Print = 1
}

export class Order implements Selectable {
    id: number;
    accountingId: string;
    userId: number;
    userAuthId: string;
    userEmail: string;
    status: OrderStatus = OrderStatus.PendingPayment;
    orderDate: Date;
    billingAddress: Address;
    shippingAddress: Address;
    shippingMethod: OrderShippingMethod;
    orderItems: OrderItem[] = [];
    isoCurrency: Currency;
    totalPrice: Dinero.Dinero;
    priceDetails: PriceDetails;
    // Selectable implementation
    selected: boolean;
    shippingRates: ShippingRate[]; //Can be null.
    shippingRatesFailureReason: string;
    purchase: Purchase; //Can be null
    billingResponsible: string;

    calculatePrices(): Order {
        const newShippingCost = this.getShippingCost();
        const newTaxesPaid = this.getTaxesPaid();
        const newTotal = this.getTotal();

        if(this.areEqual(newShippingCost,this.priceDetails?.shippingCost) &&
            this.areEqual(this.priceDetails?.taxes,newTaxesPaid) &&
            this.areEqual(newTotal, this.totalPrice)) {

            return this;
        }

        return Object.assign(new Order(), this, {
           priceDetails: Object.assign(new OrderPrices(), this.priceDetails, {
               taxesPaid: newTaxesPaid,
               shippingCost: newShippingCost
           }),
           totalPrice: newTotal
        });
    }

    areEqual(a: Dinero.Dinero, b: Dinero.Dinero): boolean {
        if (a === b) {
            return true;
        }
        if(b) {
            return a?.equalsTo(b);
        }

        return false;
    }


    getTaxesPaid(): Dinero.Dinero {
        return Dinero({amount: 0, currency: this.isoCurrency });
    }

    getShippingAddress(): Address {
        return this.shippingAddress ?? this.purchase?.shippingAddress;
    }

    getSubTotal(): Dinero.Dinero {
        let total: Dinero.Dinero | null = null;
        for (const x of this.orderItems) {
            if (!x.price) {
                continue;
            }
            if (total === null) {
                total = x.price;
            } else {
                total = total.add(x.price);
            }
        }
        if (total === null) {
            total = Dinero({ amount: 0, currency: this.isoCurrency });
        }
        return total;
    }

    getTotal(): Dinero.Dinero {
        let total = this.getSubTotal();
        const shippingCost = this.getShippingCost();
        if(shippingCost) {
            total = total.add(shippingCost);
        }
        return total;
    }

    getShippingCost(): Dinero.Dinero {
        if(this.shippingRates && this.shippingMethod) {
            const mailClass = this.shippingMethod.mailClass;
            const selShippingRate = this.shippingRates.find(x => x?.mailClass === mailClass);
            if(selShippingRate) {
                return selShippingRate.totalPrice;
            }
        }

        return null;
    }

    shouldHaveShippingRates(): boolean {
        return this.orderItems.some(x => x.distributionType === DistributionType.Print);
    }

    hasValidShippingRates(): boolean {
        return (this.shippingRates?.length ?? 0) > 0;
    }

    hasValidShippingAddressInfo(): boolean {
        const address = this.getShippingAddress();
        if(address && address.country && address.postalCode) {
            return true;
        }

        return false;
    }

    getSummedCartItems(): CountedOrderItem[] {
        const countedCartItems: CountedOrderItem[] = [];

        this.orderItems.forEach(item => {
            const existingItem = countedCartItems.find(
                cci => {
                    return (cci.orderItem.product.id === item.product.id) && (cci.orderItem.variant.id === item.variant.id) && (cci.orderItem.price.toUnit() === item.price.toUnit());
                }
            );

            if (existingItem != null) {
                existingItem.amount = existingItem.amount + 1;
                return;
            }

            const newItem: CountedOrderItem = {
                amount: 1,
                orderItem: item
            };

            countedCartItems.push(newItem);

        });

        // console.log(`Order has ${countedCartItems.length} distinct items..`);
        return countedCartItems;
    }

    setShippingMethod(mailClass: string): Order {
        const method = this.createShippingMethod(mailClass);
        if(method === null  && this.shippingMethod === null ) {
            return this;
        }

        const newOrder = Object.assign(new Order(), this, {
            shippingMethod: method
        });

        return newOrder.calculatePrices();
    }

    split(): Order[] {
        const ordersByDate = [];
        for(let i = 0; i < this.orderItems.length; i++) {
            const oi = this.orderItems[i];
            let obd = ordersByDate.find(x => x[0] === oi.variant?.scheduleDate)
            if(!obd) {
                obd = [
                    oi.variant?.scheduleDate,
                    Object.assign(new Order(), this, {
                        orderItems: []
                    })
                ];
                ordersByDate.push(obd);
            }

            obd[1].orderItems.push(oi);
        }

        return ordersByDate.map(x => x[1]);
    }

    getDispatchDate(): Date {
        let allDates = this.orderItems.map(x => x.variant.scheduleDate);
        allDates = allDates.filter(x => x !== null);
        let latestDate = new Date();
        for(let i = 0; i < allDates.length; i++) {
            console.log(`Date: ${latestDate} vs ${allDates[i]}`);
            if(allDates[i].getTime() > latestDate.getTime()) {
                latestDate = allDates[i];
            }
        }

        return latestDate;
    }

    private createShippingMethod(mailClass: string) {
        if(!mailClass) {
            return null;
        }
        const rate = this.shippingRates.find(x => x.mailClass === mailClass);
        if(rate === undefined) {
            return null;
        }
        const method = new OrderShippingMethod();
        method.mailClass = rate.mailClass;
        method.mailServiceName = rate.mailService;
        return method;
    }

    toApi(): OrderDTO {
        const dto = Object.assign({}, this, {
            totalPrice: this.totalPrice?.getAmount(),
            orderItems: this.orderItems?.map(x => x.toApi()),
            shippingRates: this.shippingRates?.map(x => x.toApi()),
            priceDetails: this.priceDetails?.toApi()
        });

        delete dto.purchase;

        return dto;
    }

    static createFromApi(dto: OrderDTO): Order {
        const currency = (dto.isoCurrency || 'USD') as Currency;
        const res: Order  = Object.assign(new Order(), dto, {
            isoCurrency: currency,
            totalPrice: Dinero({
                amount: Math.round(dto.totalPrice * 10000),
                currency,
                precision: 4,
            }),
            orderItems: dto.orderItems.map(x => OrderItem.createFromApi(x, currency)),
            priceDetails: PriceDetails.createFromApi(dto.priceDetails, currency),
        });



        if(dto.shippingRates) {
            res.shippingRates = dto.shippingRates.map(x => ShippingRate.createFromApi(x));
        }

        if(!dto.shippingMethod && (res?.shippingRates?.length ?? 0) > 0) {
            res.shippingMethod = res.createShippingMethod(res.shippingRates[0].mailClass);
        }
        console.log(res);
        return res;
    }

}

export interface OrderDTO {
    id: number;
    accountingId: string;
    userId: number;
    status: OrderStatus;
    orderDate: Date;
    billingAddress: Address;
    shippingAddress: Address;
    shippingMethod: OrderShippingMethod;
    orderItems: OrderItemDTO[];
    isoCurrency: string;
    totalPrice: number;
    priceDetails: PriceDetailsDTO;
    shippingRates: ShippingRateDTO[];
}

// OrderRefund.ts

export class RefundReason {
    id: number;
    title: string;
}

export class OrderRefund {
    refundReason: RefundReason = new RefundReason();
    shippingAmount: Dinero.Dinero;
    comment: string;
    orderRefundItems: OrderRefundItem[] = [];

    static createFromApi(dto: OrderRefundDTO, currency: Currency): OrderRefund{
        if(dto == null){return null;}
        // Empty Object + dto + anything mappings from base
        return Object.assign(new OrderRefund(), dto, {
            shippingAmount: Dinero({amount: Math.round((dto?.shippingAmount ?? 0) * 10000), currency, precision: 4})
        });
    }

    toApi(): OrderRefundDTO {
        return Object.assign({}, this, {
            shippingAmount: this.shippingAmount.toUnit()
        });
    }

}
export interface OrderRefundDTO {
    refundReason: RefundReason;
    shippingAmount: number;
    comment: string;
    orderRefundItems: OrderRefundItem[];
}

export class OrderRefundItem {
    orderItemId: number;
}

// Used for displaying more information for the dashboard.
export class OrderAdmin extends Order {
    invoicePrinted: boolean;
    transactionId: string;
    paymentProvider: string;
    transactionExternalId?: string;
    couponSaveAmount?: Dinero.Dinero;
    allTags: NameTuple[];
    orderNotes: OrderNote[];
    orderRefund?: OrderRefund;

    static createFromApi(dto: OrderAdminDTO): OrderAdmin{
        const currency = (dto.isoCurrency || 'USD') as Currency;

        // Empty Object + dto + anything mappings from base
        const orderRefund = OrderRefund.createFromApi(dto.orderRefund, currency);

        console.debug("ORDERREFUND", orderRefund);

        return Object.assign(new OrderAdmin(), dto, Order.createFromApi(dto), {
            orderRefund,
            couponSaveAmount: Dinero({
                amount: Math.round((dto?.couponSaveAmount ?? 0) * 10000),
                currency,
                precision: 4,
            }),
        });
    }

    toApi(): OrderAdminDTO {
        return Object.assign(super.toApi(), this, {
            orderRefund: this.orderRefund?.toApi(),
            couponSaveAmount: this.couponSaveAmount?.toUnit(),
        });
    }

}

export interface OrderAdminDTO extends OrderDTO {
    invoicePrinted: boolean;
    transactionId: string;
    paymentProvider: string;
    allTags: NameTuple[];
    orderNotes: OrderNote[];
    orderRefund?: OrderRefundDTO;
    externalId?: string;
    couponSaveAmount?: number;
}


//Using this for the general attached note abstract class in the backend. should be renamed to AttachedNote with an extensions for OrderNote and UserNote
export class OrderNote {
    AttachedId: number;
    level: string;
    title: string;
    message: string;
    timeCreated: Dayjs;

    toApi(): OrderNoteDTO {
        return Object.assign({}, this, {
            timeCreated: this.timeCreated.toISOString()
        });
    }

    static createFromApi(dto: OrderNoteDTO): OrderNote {
        if(dto == null){return null;}
        // Empty Object + dto + anything mappings from base
        return Object.assign(new OrderNote(), dto, {
            timeCreated: dto.timeCreated?dayjs(dto.timeCreated +'Z'):null
        });
    }
}

export interface OrderNoteDTO {
    AttachedId: number;
    level: string;
    title: string;
    message: string;
    timeCreated: string;
}

export class OrderRequest extends Order {
    CartId: number;
    TotalPrice: number;
}

// .net: InkyAPI.ApiModels.ApiOrderSummary
export class OrderSummary implements Selectable {
    id: number;
    accountingId: string;
    status: OrderStatus;
    isoCurrency: string;
    totalPrice: number;
    shippingAddress: Address;
    invoicePrinted: boolean;
    orderDate: Date;
    userId: number;
    userAuthId: string;
    userEmail: string;

    // Selectable-implementation.
    selected: boolean;
    billingResponsible: string;
}

export class OrderSummaryAdmin extends OrderSummary {
    // TODO: Make DTO-object?
    invoiceStatusId: number;
    tags: NameTuple[];
}

export class OrdersCount{
    Orders: Order[];
    Count: number;
}
